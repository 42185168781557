@import url('https://fonts.googleapis.com/css?family=Quicksand:300,400,500,700');

body {
    font-family: $font-primary;
}

.product-image:hover {
    border-color: $primary;
}

.selected-product-image-color {
    border-color: $primary ;
}

.selected-product-image-mobile-color {
    border-color: $primary ;
}
h1 {
	font-size: 36px;
}

.taradel-blue {
	color: $primary;
}
